
html { scroll-behavior: smooth; }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-h2{
    color: #fff;
    font-size: 60px !important;
    font-weight: bold;
    line-height: 1.15;
    margin-bottom: 15px;
}

.background-span{
  color: whitesmoke;
  font-size: 60px !important;
  font-weight:300;
  line-height: 1.15;
  margin-bottom: 12px !important;
}

@media only screen and (min-width: 1200px) {
  .new-container {
    max-width: 1400px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .background-h2{
    font-size: 45px !important;
  }
  .background-span{
    font-size: 45px !important;
  }
}
@media only screen and (max-width: 992px) {
  .btn-iletisim {
    width: 100%;
    margin: 10px 25px;
  }
  .background-h2{
    font-size: 40px !important;
  }
  .background-span{
    font-size: 40px !important;
  }
}

@media only screen and (max-width: 768px) {
  .background-h2{
    font-size: 35px !important;
  }
  .background-span{
    font-size: 35px !important;
  }
}
@media only screen and (max-width: 768px) {
  .background-h2{
    font-size: 30px !important;
  }
  .background-span{
    font-size: 30px !important;
  }
}

.selected-border-none{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
}

.selected-border-none:hover:focus:active{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
}





